import {
  MediaErrorDark,
  MediaErrorLight,
} from '@axiscommunications/fluent-illustrations'
import { useTrackPageView } from '@axteams-one/bws-cloud-metrics/react'
import { Link, makeStyles, tokens } from '@fluentui/react-components'
import { Trans, useTranslation } from 'react-i18next'

import { Illustration } from '../components/Illustration'
import { useThemeId } from '../providers/ThemeProvider'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: tokens.spacingHorizontalL,
    gap: tokens.spacingVerticalL,
  },
})

function NoEndToEndEncryptionPage() {
  useTrackPageView()
  const [themeId] = useThemeId()

  const styles = useStyles()
  const { t } = useTranslation()

  const SettingsLink = <Link href="/settings">{t('common.settings')}</Link>

  return (
    <div className={styles.container}>
      <Illustration
        title={t('streams.e2ee.required')}
        description={
          <>
            {t('streams.e2ee.information')}{' '}
            <Trans
              i18nKey="streams.e2ee.instructions"
              t={t}
              components={{ SettingsLink }}
            />
          </>
        }
        illustration={themeId === 'light' ? MediaErrorLight : MediaErrorDark}
      />
    </div>
  )
}

export { NoEndToEndEncryptionPage }
