import {
  Language,
  PopulatedTopbarProvider,
  usePopulatedTopbarValues,
} from '@axteams-one/populated-topbar'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { config } from '../config'
import i18n from '../i18n/i18n'
import { setCachedGroups } from '../util/group'
import { useThemeId } from './ThemeProvider'

type TopBarProviderProps = {
  children: ReactElement
}

export const TopbarProvider = ({
  children,
}: TopBarProviderProps): ReactElement => {
  const navigate = useNavigate()
  const [_, setThemeId] = useThemeId()

  const onLanguageChanged = useCallback((lang: Language) => {
    // TODO Support for right->left languages
    i18n.changeLanguage(lang)
  }, [])

  const [organizationInitiated, setOrganizationInitiated] = useState(false)

  const env = config.environment === 'prod' ? 'prod' : 'stage'

  return (
    <PopulatedTopbarProvider
      env={env}
      region="eu"
      acxApiKey={config.acxClientKey}
      applicationLubalinPath="/LubalinGraphECG-Demi.woff2"
      myApplication={{ id: 'bwl', group: 'AXIS_BODY_WORN' }}
      onLanguageChanged={onLanguageChanged}
      onOrganizationChanged={handleOrganizationChanged}
      onThemeChanged={setThemeId}
    >
      {children}
    </PopulatedTopbarProvider>
  )

  function handleOrganizationChanged() {
    // Prevent initial redirect
    if (!organizationInitiated) {
      setOrganizationInitiated(true)
      return
    }
    // Groups are specific to an organization and should therefor be reset on
    // organization change.
    setCachedGroups(new Map())

    // If a user switches organization we need to navigate them back to the
    // root of the page removing any state which might no longer be valid
    const destination = location.pathname.startsWith('/settings')
      ? '/settings'
      : '/'
    navigate(destination)
  }
}

/**
 * Returns an url populated with the parameters needed to browse to different
 * applications in mySystems, while keeping the
 * selected organization, theme and other parameters.
 * @param applicationId the id of the application you want to get
 */
export function useGetPopulatedTopbarUrl(applicationId: string) {
  const { getApplicationUrl } = usePopulatedTopbarValues()

  const [url, setUrl] = useState('')
  useEffect(() => {
    getApplicationUrl(applicationId).then((url) => setUrl(url ?? ''))
  }, [applicationId, getApplicationUrl])

  return url
}
