import { useTrackPageView } from '@axteams-one/bws-cloud-metrics/react'
import { makeStyles } from '@fluentui/react-components'
import { useOutletContext, useParams } from 'react-router-dom'

import { UndecryptableStream } from '../components/UndecryptableStream'
import { VideoPlayerContainer } from '../components/VideoPlayer/VideoPlayerContainer'
import { useContentKeys } from '../hooks/useContentKeys'
import { Stream } from '../util/stream'
import { NoEndToEndEncryptionPage } from './NoEndToEndEncryptionPage'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
})

function RecordingPlayerPage() {
  useTrackPageView()
  const styles = useStyles()
  const { streamId = '' } = useParams()
  const recordings = useOutletContext<Stream[]>()
  const decryptable = recordings.find(
    (stream) => stream.id === streamId
  )?.decryptable

  const [keys] = useContentKeys()

  if (!keys.length) {
    return <NoEndToEndEncryptionPage />
  }

  if (!decryptable) {
    return <UndecryptableStream />
  }

  return (
    <div className={styles.container}>
      <VideoPlayerContainer keys={keys} streamId={streamId} />
    </div>
  )
}

export { RecordingPlayerPage }
