import { useFlag } from '@axteams-one/bws-cloud-flags/react'
import {
  MapType,
  clusterPositions,
  getXYFromLatLon,
  setMapType,
} from '@axteams-one/bws-cloud-maps'
import { framePositions } from '@axteams-one/bws-cloud-maps'
import { Position } from '@axteams-one/bws-cloud-maps/layers/tracking'
import {
  useMap as useBwsMap,
  useTrackingLayer,
} from '@axteams-one/bws-cloud-maps/react'
import {
  Cluster,
  useClusterLayer,
} from '@axteams-one/bws-cloud-maps/react/useClusterLayer'
import {
  PositionHistory,
  useTrailsLayer,
} from '@axteams-one/bws-cloud-maps/react/useTrailsLayer'
import { useTimeSource } from '@axteams-one/bws-cloud-time-source/react/useTimeSource'
import { Color } from 'deck.gl'
import { useEffect, useRef, useState } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

import { insecureClusterMarker } from '../assets/clusterMarker'
import HeadingIconUrl from '../assets/heading.svg'
import MarkerIconUrl from '../assets/marker.svg'
import { useThemeId } from '../providers/ThemeProvider'
import { DARK_MAP_STYLES, LIGHT_MAP_STYLES } from '../util/MapStyles'
import { Flags } from '../util/flags'
import { bearerIdFromSubject } from '../util/map'
import { Stream } from '../util/stream'
import { config } from './../config'
import { TailsVisibility } from './useTailsVisibility'

type UseMapProps = {
  streams: Stream[]
  interpolatedPositions: Position[]
  interpolatedPositionHistories: Map<string, PositionHistory>
  selectedBearers: string[]
  hoveredBearers: string[]
  inactiveBearers: string[]
  mapType?: MapType
  tailsVisibility: TailsVisibility
  frameAll: boolean
  onPositionClick: (subject: string) => void
  onPositionHover: (subject?: string | string[]) => void
}

const MIN_EPH = 20
const TAILS_DURATION = 30_000
const BEARER_MARKER_SIZE = 12 // in pixels
const HOVERED_BEARER_MARKER_SIZE = 16 // in pixels
const CLUSTER_ZOOM_LIMIT = 15 // If zoom > 15, never cluster
const CLUSTER_THRESHOLD = 1 // Min number of core points before we cluster
const CLUSTER_THEMES = {
  light: { foreground: '#ffffff', background: '#ff0000' },
  dark: { foreground: '#ffffff', background: '#ff0000' },
}
const CLUSTER_DELTA = 5000
// Timeout to debounce cache updates of last known position and zoom
const CACHE_LAST_KNOWN_MAP_STATE_TIMEOUT = 500
const TAIL_THEMES = {
  roadmap: {
    light: [197, 15, 31],
    dark: [220, 98, 109],
  },
  hybrid: {
    light: [255, 0, 0],
    dark: [255, 0, 0],
  },
  satellite: {
    light: [255, 0, 0],
    dark: [255, 0, 0],
  },
}
const EPH_FILL_THEMES = {
  roadmap: {
    light: [0, 0, 0, 7],
    dark: [255, 255, 255, 7],
  },
  hybrid: {
    light: [255, 255, 255, 50],
    dark: [255, 255, 255, 50],
  },
  satellite: {
    light: [255, 255, 255, 50],
    dark: [255, 255, 255, 50],
  },
}
const EPH_LINE_THEMES = {
  roadmap: {
    light: [0, 0, 0, 0],
    dark: [0, 0, 0, 0],
  },
  hybrid: {
    light: [255, 255, 255, 255],
    dark: [255, 255, 255, 255],
  },
  satellite: {
    light: [255, 255, 255, 255],
    dark: [255, 255, 255, 255],
  },
}

const HEADING_ICON = () => ({
  url: HeadingIconUrl,
  width: 48,
  height: 48,
})

type XYPosition = Position & { x: number; y: number }

export default function useMap({
  streams,
  interpolatedPositions,
  interpolatedPositionHistories,
  selectedBearers,
  hoveredBearers,
  inactiveBearers,
  mapType = 'roadmap',
  tailsVisibility,
  frameAll,
  onPositionClick,
  onPositionHover,
}: UseMapProps) {
  const enableHeading = useFlag(Flags.MAP_HEADING)?.enabled === true
  const enableEphRadius = useFlag(Flags.MAP_EPH_RADIUS)?.enabled === true
  const enableClustering = useFlag(Flags.MAP_CLUSTERING)?.enabled === true

  const [themeId] = useThemeId()

  const { timeSource } = useTimeSource()

  const [lastKnownPosition, setLastKnownPosition] = useState(() =>
    getCachedLastKnownPosition()
  )
  // Update last known position on theme change since application is not reloaded.
  useEffect(() => {
    setLastKnownPosition(getCachedLastKnownPosition())
  }, [themeId])

  const [lastKnownZoom, setLastKnownZoom] = useState(() =>
    getCachedLastKnownZoom()
  )
  // Update last known zoom on theme change since application is not reloaded.
  useEffect(() => {
    setLastKnownZoom(getCachedLastKnownZoom())
  }, [themeId])

  const { mapElementRef, map, showLabels, zoom, bounds, projection, center } =
    useBwsMap({
      latitude: lastKnownPosition?.lat ?? config.mapDefaultLocation.latitude,
      longitude: lastKnownPosition?.lng ?? config.mapDefaultLocation.longitude,
      zoom: lastKnownZoom || config.mapDefaultLocation.zoom,
      minZoom: 3,
      maxZoom: 19,
      styles: themeId === 'light' ? LIGHT_MAP_STYLES : DARK_MAP_STYLES,
    })

  useEffect(() => {
    setMapType(map, mapType)
  }, [map, mapType])

  // Debounce cached last known zoom updates
  const cacheLastKnownZoomDebounced = useDebounceCallback(
    setCachedLastKnownZoom,
    CACHE_LAST_KNOWN_MAP_STATE_TIMEOUT
  )

  useEffect(() => {
    if (!zoom) {
      return
    }
    cacheLastKnownZoomDebounced(zoom)
  }, [cacheLastKnownZoomDebounced, zoom])

  // Debounce cached last known position updates
  const cacheLastKnownPositionDebounced = useDebounceCallback(
    setCachedLastKnownPosition,
    CACHE_LAST_KNOWN_MAP_STATE_TIMEOUT
  )

  useEffect(() => {
    if (!center) {
      return
    }
    cacheLastKnownPositionDebounced(center)
  }, [cacheLastKnownPositionDebounced, center])

  const interpolatedPositionsWithStreams = filterPositionsWithStreams(
    interpolatedPositions,
    streams
  )
  useFramePositions(
    selectedBearers,
    interpolatedPositionsWithStreams.length !== 0,
    frameAll
  )

  // Toggle mouse pointer on hover
  useEffect(() => {
    map?.map.setOptions({
      draggableCursor: hoveredBearers.length > 0 ? 'pointer' : 'grab',
    })
  }, [hoveredBearers, map])

  // Clustering
  const clusterThreshold =
    Number(useFlag(Flags.MAP_CLUSTER_THRESHOLD)?.value) || CLUSTER_THRESHOLD

  const [clusteredPositionsWithStreams, setClusteredPositionsWithStreams] =
    useState<Cluster[]>([])
  const [individualPositionsWithStreams, setIndividualPositionsWithStreams] =
    useState<Position[]>([])

  const clusterDelta =
    Number(useFlag(Flags.MAP_CLUSTER_DELTA)?.value) || CLUSTER_DELTA
  const lastClusteringTimestamp = useRef(0)
  useEffect(() => {
    lastClusteringTimestamp.current = 0 // Reset lastClusteringTimeStamp on zoom
  }, [zoom])

  useEffect(() => {
    // If time since last clustering < CLUSTER_DELTA, just refresh current
    // clusters and individual positions
    if (
      (timeSource?.getTime() || Infinity) - lastClusteringTimestamp.current <
      clusterDelta
    ) {
      // Refresh old clusters with new interpolated positions
      setClusteredPositionsWithStreams((oldClusteredPositionsWithStreams) => {
        return refreshClusters(
          oldClusteredPositionsWithStreams,
          interpolatedPositions
        )
      })
      // Refresh old individual positions with new interpolated positions
      setIndividualPositionsWithStreams((oldIndividualPositionsWithStreams) => {
        return refreshIndividualPositions(
          oldIndividualPositionsWithStreams,
          interpolatedPositions
        )
      })
      return
    }
    lastClusteringTimestamp.current = timeSource?.getTime() || 0

    // No clustering
    if (!enableClustering || zoom > CLUSTER_ZOOM_LIMIT || map === undefined) {
      setClusteredPositionsWithStreams([])
      setIndividualPositionsWithStreams(
        filterPositionsWithStreams(interpolatedPositions, streams)
      )
      return
    }

    // Cluster
    const xyPositions = createXYPositions(
      interpolatedPositions,
      streams,
      bounds,
      projection,
      zoom
    )

    // Use epsilon ("cluster distance") squared to avoid having to
    // calculate the square root in the distance function.
    const [clusters, individuals] = clusterPositions(
      xyPositions,
      (BEARER_MARKER_SIZE * 1.2) ** 2,
      clusterThreshold,
      (positionA, positionB) => {
        const xDelta = positionA.x - positionB.x
        const yDelta = positionA.y - positionB.y
        return xDelta ** 2 + yDelta ** 2
      }
    )
    setClusteredPositionsWithStreams(clusters.map(createClusterPosition))
    setIndividualPositionsWithStreams(individuals)
  }, [
    bounds,
    clusterDelta,
    clusterThreshold,
    enableClustering,
    interpolatedPositions,
    map,
    projection,
    streams,
    timeSource,
    zoom,
  ])

  // Tails layer
  let tailsPositions: Position[] = []
  if (tailsVisibility === 'all') {
    tailsPositions = individualPositionsWithStreams
  } else if (tailsVisibility === 'selected') {
    tailsPositions = individualPositionsWithStreams.filter(({ subject }) =>
      selectedBearers.includes(bearerIdFromSubject(subject))
    )
  }

  const trailsData = tailsPositions.map(
    ({ subject }) => interpolatedPositionHistories.get(subject) || []
  )

  useTrailsLayer({
    map,
    trailsData,
    trailsDuration: adaptiveTailsDuration(zoom),
    getColor: TAIL_THEMES[mapType][themeId] as Color,
    widthMinPixels: 5,
    widthMaxPixels: 5,
  })

  // Cluster layer
  useClusterLayer({
    map,
    clusters: enableClustering ? clusteredPositionsWithStreams : [],
    getSize: clusterSize,
    getIcon: clusterIcon(themeId),
    onClick: (cluster: Cluster) =>
      framePositions(map, cluster.positions, false),
    onHover: (cluster?: Cluster) =>
      onPositionHover(
        cluster && cluster.positions.map(({ subject }) => subject)
      ),
  })

  // Tracking layer
  useTrackingLayer({
    map,
    positions: individualPositionsWithStreams,
    onMarkerClick: (subject) => onPositionClick(subject),
    onMarkerHover: (subject) => onPositionHover(subject),
    markerIcon: handleMarkerIcon,
    headingIcon: HEADING_ICON,
    markerSize: (item) => handleMarkerSize(hoveredBearers, item),
    headingSize: (item) =>
      handleHeadingSize(enableHeading, zoom, item, inactiveBearers),
    ephRadius: (item) =>
      handleEphRadius(enableEphRadius, zoom, item, inactiveBearers, mapType),
    ephFillColor: EPH_FILL_THEMES[mapType][themeId] as Color,
    ephLineColor: EPH_LINE_THEMES[mapType][themeId] as Color,
    ephLineWidth: mapType === 'roadmap' ? 0 : (20 - zoom) * 0.1,
  })

  return {
    mapElementRef,
    map,
    bounds,
    projection,
    zoom,
    showLabels,
    individualPositions: individualPositionsWithStreams,
    clusteredPositions: clusteredPositionsWithStreams,
    inactiveBearers,
  }

  function useFramePositions(
    selectedBearers: string[],
    positionsExist: boolean,
    frameAll: boolean
  ) {
    // Frame all positions if no positions existed previously and no bearers are
    // selected.
    useEffect(() => {
      if (positionsExist && selectedBearers.length === 0) {
        framePositions(map, interpolatedPositionsWithStreams)
      }
    }, [positionsExist, selectedBearers])

    useEffect(() => {
      if (positionsExist && frameAll) {
        framePositions(map, interpolatedPositionsWithStreams)
      }
    }, [positionsExist, frameAll])

    useEffect(() => {
      if (selectedBearers.length === 0) {
        return
      }
      framePositions(
        map,
        filterSelectedBearersPositions(
          interpolatedPositionsWithStreams,
          selectedBearers
        )
      )
    }, [selectedBearers])
  }
}

/**
 * Filters out the positions that do not have a corresponding stream.
 */
function filterPositionsWithStreams(positions: Position[], streams: Stream[]) {
  return positions.filter((position: Position) => {
    const bearerId = bearerIdFromSubject(position.subject)
    return streams.some((stream) => stream.bearerId === bearerId)
  })
}

/**
 * Filters out the positions that do not belong to a selected bearer.
 */
function filterSelectedBearersPositions(
  positions: Position[],
  bearers: string[]
) {
  return positions.filter((position) =>
    bearers.includes(bearerIdFromSubject(position.subject))
  )
}

/**
 * Return marker icon if position active. Otherwise return inactive marker icon.
 */
function handleMarkerIcon() {
  return {
    url: MarkerIconUrl,
    width: BEARER_MARKER_SIZE * 2,
    height: BEARER_MARKER_SIZE * 2,
  }
}

/** Return marker size based on hover. */
function handleMarkerSize(hoveredBearers: string[], position?: Position) {
  const subject = position?.subject
  if (!subject) {
    return BEARER_MARKER_SIZE
  }

  const bearerId = bearerIdFromSubject(subject)
  const hovered = hoveredBearers.includes(bearerId)

  return hovered ? HOVERED_BEARER_MARKER_SIZE : BEARER_MARKER_SIZE
}

/**
 * Return 48 if heading feature is enabled, position active, and large enough
 * zoom level. Otherwise return 0.
 */
function handleHeadingSize(
  enableHeading: boolean,
  zoom: number,
  position: Position | undefined,
  inactiveBearers: string[]
) {
  if (!position || !enableHeading) {
    return 0
  }

  const inactive = inactiveBearers.includes(
    bearerIdFromSubject(position.subject)
  )
  const zoomedOut = (zoom || 0) <= 14

  if (inactive || zoomedOut) {
    return 0
  }

  return 48
}

/**
 * Return EPH if EPH radius feature is enabled, position active, large enough
 * zoom level, and large enough eph. Otherwise return 0.
 */
function handleEphRadius(
  enableEphRadius: boolean,
  zoom: number,
  position: Position | undefined,
  inactiveBearers: string[],
  mapType: MapType
) {
  if (!position || !enableEphRadius) {
    return 0
  }

  const inactive = inactiveBearers.includes(
    bearerIdFromSubject(position.subject)
  )
  const zoomedOut = (zoom || 0) <= 14
  const precisePosition = position.eph <= 3
  const satelliteZoomedIn = mapType === 'hybrid' && zoom > 17

  if (inactive || zoomedOut || precisePosition || satelliteZoomedIn) {
    return 0
  }

  return Math.max(MIN_EPH, position.eph)
}

/**
 * Adapt duration based on zoom level to give a consistent visual presentation
 * of speed and direction.
 * @param {number} zoom
 * @returns {number} - Duration in milliseconds
 */
function adaptiveTailsDuration(zoom: number) {
  return (1 - Math.max((zoom || 0) - 16, 0) / 9) * TAILS_DURATION
}

// Clustering

/**
 * Size of cluster marker based upon number of positions in cluster
 * (area scales linearly with number of positions).
 * @param {Cluster} cluster
 * @returns {number} - Diameter in pixels
 */
function clusterSize(cluster: Cluster) {
  return (
    Math.sqrt(2 + Math.log10(cluster.positions.length - 1) * 3) *
    BEARER_MARKER_SIZE
  )
}

/**
 * Creates a cluster icon based upon the size of the cluster.
 * @param cluster
 */
const clusterIcon = (themeId: string) => {
  const colors =
    themeId === 'light' ? CLUSTER_THEMES.light : CLUSTER_THEMES.dark
  return (cluster: Cluster) => {
    const size = clusterSize(cluster) * 2
    // No risk of XSS as we have full control the arguments passed to
    // insecureClusterMarker.
    const svgMarker = insecureClusterMarker(
      size,
      0,
      cluster.positions.length,
      colors
    )

    return {
      url: `data:image/svg+xml;base64,${btoa(svgMarker)}`,
      width: size,
      height: size,
    }
  }
}

/**
 * Convert an array of Positions into XYPositions by adding xy coordinates.
 * @param positions
 * @param streams
 * @param bounds
 * @param projection
 * @param zoom
 * @returns
 */
function createXYPositions(
  positions: Position[],
  streams: Stream[],
  bounds: google.maps.LatLngBounds | undefined,
  projection: google.maps.Projection | undefined,
  zoom: number | undefined
): XYPosition[] {
  return filterPositionsWithStreams(positions, streams).map(
    (position): XYPosition => {
      const [x, y] = getXYFromLatLon(
        [position.latitude, position.longitude],
        bounds,
        projection,
        zoom
      ) ?? [0, 0]
      return { ...position, x, y }
    }
  )
}

/**
 * Create a Cluster out of multiple positions.
 * @param {Position[]} positions
 */
function createClusterPosition(positions: Position[]): Cluster {
  let accumulatedLatitude = 0
  let accumulatedLongitude = 0
  for (const { latitude, longitude } of positions) {
    accumulatedLatitude += latitude
    accumulatedLongitude += longitude
  }

  return {
    latitude: accumulatedLatitude / positions.length,
    longitude: accumulatedLongitude / positions.length,
    positions,
  }
}

function refreshClusters(
  oldClusteredPositionsWithStreams: Cluster[],
  interpolatedPositions: Position[]
) {
  return oldClusteredPositionsWithStreams.map((cluster) => {
    const refreshedPositions = []
    for (const positionInCluster of cluster.positions) {
      const newPosition = interpolatedPositions.find(
        (position) => position.subject === positionInCluster.subject
      )
      if (newPosition !== undefined) {
        refreshedPositions.push(newPosition)
      }
    }
    return createClusterPosition(refreshedPositions)
  })
}

function refreshIndividualPositions(
  oldIndividualPositionsWithStreams: Position[],
  interpolatedPositions: Position[]
) {
  const refreshedPositions = []
  for (const oldPosition of oldIndividualPositionsWithStreams) {
    const newPosition = interpolatedPositions.find(
      (position) => position.subject === oldPosition.subject
    )
    if (newPosition !== undefined) {
      refreshedPositions.push(newPosition)
    }
  }
  return refreshedPositions
}

function getCachedLastKnownPosition() {
  try {
    const lastKnownPosition = localStorage.getItem('lastKnownPosition')

    if (!lastKnownPosition) {
      return undefined
    }

    const parsedLastKnownPosition = JSON.parse(
      lastKnownPosition
    ) as google.maps.LatLngLiteral

    return parsedLastKnownPosition
  } catch {
    console.warn('Failed parsing cached last known position')
    return undefined
  }
}

function setCachedLastKnownPosition(position: google.maps.LatLng) {
  try {
    localStorage.setItem('lastKnownPosition', JSON.stringify(position))
  } catch (error) {
    console.warn('Failed saving cached last known position')
  }
}

function getCachedLastKnownZoom() {
  try {
    const lastKnownZoom = localStorage.getItem('lastKnownZoom')

    if (!lastKnownZoom) {
      return undefined
    }

    const parsedLastKnownZoom = JSON.parse(lastKnownZoom) as number

    return parsedLastKnownZoom
  } catch {
    console.warn('Failed parsing cached last known zoom level')
    return undefined
  }
}

function setCachedLastKnownZoom(zoom: number) {
  try {
    localStorage.setItem('lastKnownZoom', JSON.stringify(zoom))
  } catch (error) {
    console.warn('Failed saving cached last known zoom')
  }
}
