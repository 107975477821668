import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Text,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'

import { Group } from '../util/group'
import { GroupDialogState } from './StreamList'

const useStyles = makeStyles({
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalM,
  },
  dialogActions: {
    justifyContent: 'end',
  },
})

type RemoveDialogProps = {
  onOpenChange: (data: GroupDialogState) => void
  onRemoveGroup: (group: Group) => void
  group: Group
}

export function RemoveGroupDialog({
  onOpenChange,
  onRemoveGroup,
  group,
}: RemoveDialogProps) {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog
      open={true}
      onOpenChange={(_, data) => {
        onOpenChange({ openDialog: data.open ? 'remove' : null, group: group })
      }}
    >
      <DialogSurface>
        <DialogBody className={styles.dialogBody}>
          <DialogTitle>{t('streams.delete-group.remove-group')}</DialogTitle>
          <DialogContent className={styles.dialogContent}>
            <Text>
              {group.name
                ? t('streams.delete-group.confirm', { groupName: group.name })
                : t('streams.delete-group.confirm-no-name')}
            </Text>
          </DialogContent>
          <DialogActions className={styles.dialogActions}>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">
                {t('common.action.cancel')}
              </Button>
            </DialogTrigger>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="primary" onClick={() => onRemoveGroup(group)}>
                {t('common.action.remove')}
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
