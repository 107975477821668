import { MapType } from '@axteams-one/bws-cloud-maps'
import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuGroupHeader,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import {
  Add16Regular,
  Layer20Regular,
  LocationTargetSquare20Regular,
  MountainTrail20Regular,
  Subtract16Regular,
} from '@fluentui/react-icons'
import { useTranslation } from 'react-i18next'

import { TailsVisibility } from '../../hooks/useTailsVisibility'

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    right: 0,
    margin: tokens.spacingVerticalM,
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: tokens.spacingHorizontalXS,
    padding: tokens.spacingHorizontalXS,
    backgroundColor: tokens.colorNeutralBackground4,
  },
  mainButtonContainer: {
    rowGap: tokens.spacingVerticalS,
  },
  divider: {
    width: '80%',
    height: '1px',
    marginBlock: '1px',
    border: 0,
    backgroundColor: tokens.colorNeutralStroke2,
  },
})

type MapTypeMenuProps = {
  mapType: MapType
  onMapTypeChange: (mapType: MapType) => void
}

type MapTypeItemsProps = {
  onMapTypeChange: (mapType: MapType) => void
}

type TailsVisibilityMenuProps = {
  tailsVisibility: TailsVisibility
  onTailsVisibilityChange: (tailsVisibility: TailsVisibility) => void
}

type TailsVisibilityItemsProps = {
  onTailsVisibilityChange: (tailsVisibility: TailsVisibility) => void
}

type MapControlsProps = {
  mapType: MapType
  tailsVisibility: TailsVisibility
  zoom: number
  container: HTMLDivElement | null
  onFullscreen: () => void
  onFrameAll: () => void
  onMapTypeChange: (mapType: MapType) => void
  onTailsVisibilityChange: (tailsVisibility: TailsVisibility) => void
  onZoomChange: (zoom: number) => void
}

export function MapControls({
  mapType,
  tailsVisibility,
  zoom,
  onFrameAll,
  onMapTypeChange,
  onTailsVisibilityChange,
  onZoomChange,
}: MapControlsProps) {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div
        className={mergeClasses(
          styles.buttonContainer,
          styles.mainButtonContainer
        )}
      >
        <Tooltip
          content={t('streams.frame-all')}
          mountNode={document.fullscreenElement}
          relationship="label"
          positioning={'before'}
        >
          <Button
            appearance="subtle"
            icon={<LocationTargetSquare20Regular />}
            onClick={onFrameAll}
          />
        </Tooltip>
        <MapTypeMenu mapType={mapType} onMapTypeChange={onMapTypeChange} />
        <TailsVisibilityMenu
          tailsVisibility={tailsVisibility}
          onTailsVisibilityChange={onTailsVisibilityChange}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Tooltip
          content={t('streams.zoom-in')}
          mountNode={document.fullscreenElement}
          relationship={'label'}
          positioning={'before'}
        >
          <Button
            data-testid={'zoomIn'}
            appearance="subtle"
            icon={<Add16Regular />}
            onClick={() => onZoomChange(zoom + 1)}
          />
        </Tooltip>
        <hr className={styles.divider} />
        <Tooltip
          content={t('streams.zoom-out')}
          mountNode={document.fullscreenElement}
          relationship={'label'}
          positioning={'before'}
        >
          <Button
            data-testid={'zoomOut'}
            appearance="subtle"
            icon={<Subtract16Regular />}
            onClick={() => onZoomChange(zoom - 1)}
          />
        </Tooltip>
      </div>
    </div>
  )
}

function MapTypeMenu({ mapType, onMapTypeChange }: MapTypeMenuProps) {
  const { t } = useTranslation()

  return (
    <Menu
      checkedValues={{ mapType: [mapType] }}
      mountNode={document.fullscreenElement}
    >
      <MenuTrigger disableButtonEnhancement>
        <Tooltip
          content={t('streams.map.map-type')}
          mountNode={document.fullscreenElement}
          relationship={'label'}
          positioning={'before'}
        >
          <MenuButton icon={<Layer20Regular />} appearance="subtle" />
        </Tooltip>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuGroup>
            <MenuGroupHeader>{t('streams.map.map-type')}</MenuGroupHeader>
            <MapTypeItems onMapTypeChange={onMapTypeChange} />
          </MenuGroup>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}

function MapTypeItems({ onMapTypeChange }: MapTypeItemsProps) {
  const { t } = useTranslation()

  const mapTypes: { name: string; value: MapType }[] = [
    { name: t('streams.map.default'), value: 'roadmap' },
    { name: t('streams.map.satellite'), value: 'hybrid' },
  ]

  return mapTypes.map((mapType) => (
    <MenuItemRadio
      name={'mapType'}
      key={mapType.name}
      value={mapType.value}
      onClick={() => onMapTypeChange(mapType.value)}
    >
      {mapType.name}
    </MenuItemRadio>
  ))
}

function TailsVisibilityMenu({
  tailsVisibility,
  onTailsVisibilityChange,
}: TailsVisibilityMenuProps) {
  const { t } = useTranslation()

  return (
    <Menu
      checkedValues={{ tailsVisibility: [tailsVisibility] }}
      mountNode={document.fullscreenElement}
    >
      <MenuTrigger disableButtonEnhancement>
        <Tooltip
          content={t('streams.map.tails-visibility')}
          mountNode={document.fullscreenElement}
          relationship={'label'}
          positioning={'before'}
        >
          <MenuButton icon={<MountainTrail20Regular />} appearance="subtle" />
        </Tooltip>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuGroup>
            <MenuGroupHeader>
              {t('streams.map.tails-visibility')}
            </MenuGroupHeader>
            <TailsVisibilityItems
              onTailsVisibilityChange={onTailsVisibilityChange}
            />
          </MenuGroup>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}

function TailsVisibilityItems({
  onTailsVisibilityChange,
}: TailsVisibilityItemsProps) {
  const { t } = useTranslation()

  const tailsVisibility: { name: string; value: TailsVisibility }[] = [
    { name: t('streams.map.tails-visibility-all'), value: 'all' },
    { name: t('streams.map.tails-visibility-selected'), value: 'selected' },
    { name: t('streams.map.tails-visibility-none'), value: 'none' },
  ]

  return tailsVisibility.map((tailsVisibility) => (
    <MenuItemRadio
      name={'tailsVisibility'}
      key={tailsVisibility.name}
      value={tailsVisibility.value}
      onClick={() => onTailsVisibilityChange(tailsVisibility.value)}
    >
      {tailsVisibility.name}
    </MenuItemRadio>
  ))
}
