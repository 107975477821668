import { Button, mergeClasses, tokens } from '@fluentui/react-components'
import { Dismiss20Regular } from '@fluentui/react-icons'
import { makeStyles } from '@griffel/react'
import { MutableRefObject, createRef, useEffect, useRef, useState } from 'react'

import { StoredKey } from '../hooks/useContentKeys'
import { Stream } from '../util/stream'
import { NoGroupStreams } from './NoGroupStreams'
import { UndecryptableStream } from './UndecryptableStream'
import { StreamEnded } from './VideoPlayer/StreamEnded'
import { VideoPlayerContainer } from './VideoPlayer/VideoPlayerContainer'

interface GridItemLayout {
  element: Element
  x: number
  y: number
  width: number
  height: number
}

const useStyles = makeStyles({
  grid: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: '1fr 1fr',
    height: '100%',
    width: '100%',
  },
  oneStreamGrid: {
    gridTemplateAreas: `
    "itemZero itemZero"
    "itemZero itemZero"
    `,
  },
  twoStreamGrid: {
    gridTemplateAreas: `
    "itemZero itemOne"
    "itemZero itemOne"
    `,
  },
  threeStreamGrid: {
    gridTemplateAreas: `
    "itemZero itemOne"
    "itemTwo ."
    `,
  },
  fourStreamGrid: {
    gridTemplateAreas: `
    "itemZero itemOne"
    "itemTwo itemThree"
    `,
  },
  gridItem: {
    border: `${tokens.spacingHorizontalXXS} solid ${tokens.colorNeutralStroke2}`,
  },
  gridItemZero: {
    gridArea: 'itemZero',
  },
  gridItemOne: {
    gridArea: 'itemOne',
  },
  gridItemTwo: {
    gridArea: 'itemTwo',
  },
  gridItemThree: {
    gridArea: 'itemThree',
  },
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    ':hover > .dismissButton': {
      opacity: '100%',
    },
  },
  dismissButton: {
    opacity: '0%',
    transition: 'opacity 300ms',
    position: 'absolute',
    right: 0,
    top: 0,
    margin: tokens.spacingHorizontalS,
  },
})

type GroupViewerProps = {
  keys: StoredKey[]
  streams: Stream[]
  recordingIds: string[]
  onViewRecording: (streamId: string) => void
  onDismissStream: (streamId: string) => void
}

export function GroupViewer({
  keys,
  streams,
  recordingIds,
  onViewRecording,
  onDismissStream,
}: GroupViewerProps) {
  const styles = useStyles()

  const gridContainerRef = useRef<HTMLDivElement>(null)
  const refs = useRef<Record<string, MutableRefObject<HTMLDivElement>>>({})
  const oldGridItemsLayout = useRef<GridItemLayout[]>()
  const [renderedStreams, setRenderedStreams] = useState<Stream[]>(streams)

  // If a stream is started or ended, update the list of streams to be rendered.
  useEffect(() => {
    setRenderedStreams(streams)

    if (gridContainerRef.current) {
      oldGridItemsLayout.current = getGridItemsLayout(gridContainerRef.current)
    }
  }, [streams])

  // If the list of streams to be rendered is updated and there is a previous
  // grid layout stored, animate the transition between the previous grid layout
  // and the new grid layout.
  useEffect(() => {
    if (!oldGridItemsLayout.current || !gridContainerRef.current) {
      return
    }
    const newGridItemsLayout = getGridItemsLayout(gridContainerRef.current)
    animateGridItems(oldGridItemsLayout.current, newGridItemsLayout)
  }, [renderedStreams])

  if (renderedStreams.length === 0) {
    return <NoGroupStreams />
  }

  renderedStreams.forEach((stream) => {
    refs.current[stream.id] = refs.current[stream.id] || createRef()
  })

  const gridClassName = () => {
    let classes = styles.grid

    switch (renderedStreams.length) {
      case 1:
        classes = mergeClasses(classes, styles.oneStreamGrid)
        break
      case 2:
        classes = mergeClasses(classes, styles.twoStreamGrid)
        break
      case 3:
        classes = mergeClasses(classes, styles.threeStreamGrid)
        break
      case 4:
        classes = mergeClasses(classes, styles.fourStreamGrid)
        break
    }
    return classes
  }

  const gridItemClassName = (index: number) => {
    let classes = styles.gridItem
    switch (index) {
      case 0:
        classes = mergeClasses(classes, styles.gridItemZero)
        break
      case 1:
        classes = mergeClasses(classes, styles.gridItemOne)
        break
      case 2:
        classes = mergeClasses(classes, styles.gridItemTwo)
        break
      case 3:
        classes = mergeClasses(classes, styles.gridItemThree)
        break
    }
    return classes
  }

  return (
    <div className={gridClassName()} ref={gridContainerRef}>
      {renderedStreams?.map(renderedStreamsMapper)}
    </div>
  )

  function renderedStreamsMapper(stream: Stream, index: number) {
    if (stream.decryptable === false) {
      return (
        <div
          key={stream.id}
          className={gridItemClassName(index)}
          ref={refs.current[stream.id]}
        >
          <UndecryptableStream />
        </div>
      )
    }

    const watchingRecording = recordingIds.includes(stream.id)

    if (!stream.ongoing && !watchingRecording) {
      return (
        <div
          key={stream.id}
          className={gridItemClassName(index)}
          ref={refs.current[stream.id]}
        >
          <StreamEnded
            streamId={stream.id}
            onViewRecording={onViewRecording}
            onDismiss={onDismissStream}
          />
        </div>
      )
    }

    return (
      <div
        key={stream.id}
        className={mergeClasses(gridItemClassName(index), styles.container)}
        ref={refs.current[stream.id]}
      >
        <VideoPlayerContainer keys={keys} streamId={stream.id} inGroupView />
        {watchingRecording && (
          <Button
            className={mergeClasses(styles.dismissButton, 'dismissButton')}
            icon={<Dismiss20Regular />}
            onClick={() => onDismissStream(stream.id)}
          />
        )}
      </div>
    )
  }
}

function getGridItemsLayout(container: Element): GridItemLayout[] {
  return Array.from(container.children).map((item) => {
    const rect = item.getBoundingClientRect()
    return {
      element: item,
      x: rect.left,
      y: rect.top,
      width: rect.right - rect.left,
      height: rect.bottom - rect.top,
    }
  })
}

function animateGridItems(
  oldItemsLayout: GridItemLayout[],
  newItemsLayout: GridItemLayout[]
): void {
  for (const newItemLayout of newItemsLayout) {
    const oldItemLayout = oldItemsLayout.find(
      (oldItemLayout) => oldItemLayout.element === newItemLayout.element
    )

    if (oldItemLayout) {
      const translateX = oldItemLayout.x - newItemLayout.x
      const translateY = oldItemLayout.y - newItemLayout.y
      const scaleX = oldItemLayout.width / newItemLayout.width
      const scaleY = oldItemLayout.height / newItemLayout.height

      newItemLayout.element.animate(
        [
          {
            transform: `translate(${translateX}px, ${translateY}px) scale(${scaleX}, ${scaleY})`,
          },
          { transform: 'none' },
        ],
        {
          duration: 250,
          easing: 'ease-out',
        }
      )
    } else {
      newItemLayout.element.animate(
        [
          {
            transform: `scale(0.95, 0.95)`,
          },
          { transform: 'none' },
        ],
        {
          duration: 250,
          easing: 'ease-in',
        }
      )
    }
  }
}
