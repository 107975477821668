import { useTrackPageView } from '@axteams-one/bws-cloud-metrics/react'
import {
  Toast,
  ToastTitle,
  makeStyles,
  useToastController,
} from '@fluentui/react-components'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { GroupViewer } from '../components/GroupViewer'
import { NoGroupBearers } from '../components/NoGroupBearers'
import { TOASTER_ID } from '../constants'
import { useContentKeys } from '../hooks/useContentKeys'
import { useGroupStreams } from '../hooks/useGroupStreams'
import { bearerIdsFromStringToList } from '../util/group'
import { NoEndToEndEncryptionPage } from './NoEndToEndEncryptionPage'
import { useStreamsPageContext } from './StreamsPage'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
})

function TemporaryGroupViewerPage() {
  useTrackPageView()
  const styles = useStyles()
  const [keys] = useContentKeys()
  const { t } = useTranslation()

  const { bearerIds = '' } = useParams()
  const bearers = useMemo(
    () => bearerIdsFromStringToList(bearerIds),
    [bearerIds]
  )
  const { streams } = useStreamsPageContext()
  const { groupStreams, handleDismissStream } = useGroupStreams({
    streams,
    bearerIds: bearers,
  })
  // Ended streams for which recording should be shown, i.e. user has clicked
  // 'view recording'.
  const [recordingIds, setRecordingIds] = useState<string[]>([])
  const { dispatchToast } = useToastController(TOASTER_ID)

  useEffect(() => {
    if (!bearers.length || !groupStreams.length) {
      return
    }

    if (bearers.length !== groupStreams.length) {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {t('streams.only-streaming-of-total', {
              streaming: groupStreams.length,
              total: bearers.length,
            })}
          </ToastTitle>
        </Toast>,
        { intent: 'info' }
      )
    }
  }, [groupStreams.length, bearers.length, dispatchToast, t])

  if (!keys.length) {
    return <NoEndToEndEncryptionPage />
  }

  if (bearers.length === 0) {
    return <NoGroupBearers />
  }

  return (
    <div className={styles.container}>
      <GroupViewer
        keys={keys}
        streams={groupStreams}
        recordingIds={recordingIds}
        onViewRecording={handleViewRecording}
        onDismissStream={handleDismissStream}
      />
    </div>
  )

  function handleViewRecording(streamId: string) {
    setRecordingIds((previous) => [...previous, streamId])
  }
}

export { TemporaryGroupViewerPage }
