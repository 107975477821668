import { PasswordInput } from '@axiscommunications/fluent-password-input'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  InputOnChangeData,
  Text,
  Tooltip,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalM,
  },
  dialogActions: {
    justifyContent: 'end',
  },
  downloadButton: {
    marginBlockStart: tokens.spacingVerticalS,
    marginBlockEnd: tokens.spacingVerticalXS,
    marginInlineStart: tokens.spacingHorizontalXL,
    height: 'min-content',
  },
})

type ImportDialogProps = {
  onExportKeys: (passphrase: string) => void
}

export function ExportKeysDialog({ onExportKeys }: ImportDialogProps) {
  const styles = useStyles()
  const { t } = useTranslation()
  const [passphrase, setPassphrase] = useState('')

  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <Tooltip
          content={t('settings-encryption.export-keys-dialog.download-tooltip')}
          relationship="label"
        >
          <Button className={styles.downloadButton} appearance="outline">
            {t('common.action.download')}
          </Button>
        </Tooltip>
      </DialogTrigger>
      <DialogSurface>
        <form onSubmit={handleSubmit}>
          <DialogBody className={styles.dialogBody}>
            <DialogTitle>Download keys</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Text>
                {t('settings-encryption.export-keys-dialog.download-dialog')}
              </Text>
              <PasswordInput onChange={handleChange} value={passphrase} />
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <DialogTrigger disableButtonEnhancement>
                <Button size="medium" appearance="secondary">
                  {t('common.action.cancel')}
                </Button>
              </DialogTrigger>
              <DialogTrigger disableButtonEnhancement>
                <Button type="submit" size="medium" appearance="primary">
                  {t('common.action.download')}
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    onExportKeys(passphrase)
  }

  function handleChange(
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setPassphrase(data.value)
  }
}
