import {
  Body1,
  Card,
  Spinner,
  Subtitle1,
  Toast,
  ToastBody,
  ToastTitle,
  makeStyles,
  tokens,
  useToastController,
} from '@fluentui/react-components'
import { Alert } from '@fluentui/react-components/unstable'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TOASTER_ID } from '../../constants'
import { ErrorCode } from '../../graphql/__generated__/graphql'
import { ApiStatus } from '../../hooks/types'
import { useDisclaimSystem } from '../../hooks/useDisclaimSystem'
import { useSystems } from '../../hooks/useSystems'
import { Api, useUrqlClient } from '../../hooks/useUrqlClient'
import { useCurrentOrganization } from '../../providers/CurrentOrganizationProvider'
import { DisclaimSystemList } from './DisclaimSystemList'

const useStyles = makeStyles({
  card: {
    maxWidth: '600px',
  },
  disclaimError: {
    marginBlockEnd: tokens.spacingVerticalM,
  },
})

export function DisclaimSettings() {
  const styles = useStyles()
  const client = useUrqlClient(Api.Bwo)
  const { dispatchToast, dismissToast } = useToastController(TOASTER_ID)
  const { id: organizationId, name: organizationName } =
    useCurrentOrganization()
  const [systemsResult, reexecuteGetSystems] = useSystems(
    client,
    organizationId
  )
  const [disclaimError, setDisclaimError] = useState<string>('')
  const disclaimSystem = useDisclaimSystem()
  const { t } = useTranslation()

  if (
    systemsResult.status === ApiStatus.Idle ||
    systemsResult.status === ApiStatus.Loading
  ) {
    return null
  }

  if (systemsResult.status === ApiStatus.Rejected) {
    return (
      <Card className={styles.card}>
        <Subtitle1>
          {t('settings-connected-systems.connected-systems')}
        </Subtitle1>
        <Body1>
          {t('settings-connected-systems.problem-fetching-systems')}
        </Body1>
      </Card>
    )
  }

  return (
    <Card size="large" className={styles.card}>
      <Subtitle1>{t('settings-connected-systems.connected-systems')}</Subtitle1>
      <Body1>
        <Trans
          i18nKey="settings-connected-systems.organization-connected-systems"
          t={t}
          values={{ organizationName }}
          components={{ strong: <strong /> }}
        />
      </Body1>
      <div>
        {disclaimError && (
          <Alert intent="error" className={styles.disclaimError}>
            {disclaimError}
          </Alert>
        )}
        <DisclaimSystemList
          list={systemsResult.data}
          onDisclaim={handleDisclaim}
          organizationName={organizationName}
        />
      </div>
    </Card>
  )

  async function handleDisclaim(systemId: string) {
    // Reset disclaim error so that earlier errors won't confuse the user
    setDisclaimError('')

    dispatchToast(
      <Toast>
        <ToastTitle media={<Spinner size="tiny" />}>
          {t('settings-connected-systems.disconnecting-system')}
        </ToastTitle>
      </Toast>,
      { toastId: 'progress-toast' }
    )

    const result = await disclaimSystem(systemId)
    dismissToast('progress-toast')

    if (result?.status === ApiStatus.Rejected) {
      if (result.error === ErrorCode.BwoUnauthorized) {
        dispatchToast(
          <Toast>
            <ToastTitle>{t('common.unauthorized')}</ToastTitle>
            <ToastBody>
              {t('settings-connected-systems.unauthorized-disconnect')}
            </ToastBody>
          </Toast>,
          { intent: 'error' }
        )
      } else {
        dispatchToast(
          <Toast>
            <ToastTitle>
              {t('settings-connected-systems.failed-to-disconnect')}
            </ToastTitle>
            <ToastBody>
              {t('settings-connected-systems.failed-to-disconnect-information')}
            </ToastBody>
          </Toast>,
          { intent: 'error' }
        )
      }
    } else if (result?.status === ApiStatus.Resolved) {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {t('settings-connected-systems.successfully-disconnected')}
          </ToastTitle>
        </Toast>,
        { intent: 'success' }
      )
      reexecuteGetSystems()
    }
  }
}
