import {
  Caption1,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'

type NameBadgeProps = {
  isRecording: boolean
  shouldBeLive: boolean
  bearerName?: string
  bearerShowId?: string
}

const useStyles = makeStyles({
  live: {
    '& .liveDot': {
      backgroundColor: '#FF0000',
    },
  },
  container: {
    display: 'none',
    alignItems: 'center',
    position: 'absolute',
    left: tokens.spacingHorizontalXS,
    bottom: tokens.spacingVerticalM,
    maxWidth: '150px',
    opacity: '0.95',
    backgroundColor: tokens.colorNeutralBackground2,
    gap: tokens.spacingHorizontalXS,
    borderRadius: tokens.spacingVerticalXS,
    padding: `${tokens.spacingVerticalXS} ${tokens.spacingHorizontalS}`,
  },
  liveDot: {
    backgroundColor: tokens.colorBrandBackground,
    height: '7px',
    minWidth: '7px',
    width: '7px',
    borderRadius: '50%',
    marginInlineEnd: tokens.spacingHorizontalXS,
  },
  bearerName: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
})

export default function NameBadge({
  isRecording,
  shouldBeLive,
  bearerName,
  bearerShowId,
}: NameBadgeProps) {
  const styles = useStyles()

  const containerClassName = () => {
    let classes = mergeClasses(styles.container, 'nameBadge')

    if (shouldBeLive) {
      classes = mergeClasses(classes, styles.live)
    }

    return classes
  }
  const { t } = useTranslation()

  return (
    <div className={containerClassName()}>
      {!isRecording && (
        <span className={mergeClasses(styles.liveDot, 'liveDot')} />
      )}
      <Caption1 className={styles.bearerName}>
        {bearerName || t('common.na')}
        {bearerShowId && ` (${bearerShowId})`}
      </Caption1>
    </div>
  )
}
