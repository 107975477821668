import {
  NoContentDark,
  NoContentLight,
} from '@axiscommunications/fluent-illustrations'
import { makeStyles, tokens } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'

import { useThemeId } from '../providers/ThemeProvider'
import { Illustration } from './Illustration'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: tokens.spacingVerticalM,
    padding: tokens.spacingHorizontalL,
  },
})

function NoGroupStreams() {
  const styles = useStyles()
  const [themeId] = useThemeId()
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <Illustration
        title={t('streams.group-has-no-active-streams')}
        illustration={themeId === 'light' ? NoContentLight : NoContentDark}
      />
    </div>
  )
}

export { NoGroupStreams }
