import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogOpenChangeData,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  tokens,
  typographyStyles,
} from '@fluentui/react-components'
import { Warning20Filled } from '@fluentui/react-icons'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { System } from '../../hooks/useSystems'

const useStyles = makeStyles({
  dialogTitle: {
    marginBlockEnd: '1em',
  },
  dialogContent: {
    display: 'flex',
    columnGap: tokens.spacingHorizontalS,
  },
  warningIcon: {
    flexShrink: 0,
    color: tokens.colorNeutralForeground2BrandHover,
  },
  warningText: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1em',
    marginBlockEnd: '1em',
  },
  textBody: typographyStyles.body1,
})

type DisclaimDialogProps = {
  onDisclaim: (systemId: string) => void
  organizationName: string
  system: System
}

export function DisclaimDialog({
  organizationName,
  onDisclaim,
  system,
}: DisclaimDialogProps) {
  const styles = useStyles()
  const { t } = useTranslation()
  const [accepted, setAccepted] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const systemTitle = getSystemTitle(system)
  return (
    <Dialog open={open} onOpenChange={handleOnOpenChange}>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="subtle" size="small">
          {t('common.action.disconnect')}
        </Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle className={styles.dialogTitle}>
            {t('settings-connected-systems.disconnect-system')}
          </DialogTitle>
          <DialogContent>
            <div className={styles.dialogContent}>
              <Warning20Filled className={styles.warningIcon} />
              <div className={styles.warningText}>
                <p className={styles.textBody}>
                  {t('settings-connected-systems.disconnect-system-warning')}
                </p>
                <p className={styles.textBody}>
                  <Trans
                    i18nKey="settings-connected-systems.disconnect-system-confirm"
                    t={t}
                    values={{ systemTitle, organizationName }}
                    components={{ strong: <strong /> }}
                  />
                </p>
                <Checkbox
                  label={t(
                    'settings-connected-systems.disconnect-system-checkbox'
                  )}
                  checked={accepted}
                  onChange={(event) => setAccepted(event.target.checked)}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">{t('common.action.close')}</Button>
            </DialogTrigger>
            <Button
              appearance="primary"
              disabled={!accepted}
              onClick={handleDisclaimClick}
            >
              {t('common.action.disconnect')}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )

  function handleOnOpenChange(
    _event:
      | React.KeyboardEvent<HTMLElement>
      | React.MouseEvent<HTMLElement, MouseEvent>,
    data: DialogOpenChangeData
  ) {
    setAccepted(false)
    setOpen(data.open)
  }

  function handleDisclaimClick() {
    setOpen(false)
    onDisclaim(system.systemID)
  }

  function getSystemTitle(system: System) {
    const name = system.name.trim()
    const id = system.systemID.trim()
    return name && id ? `${name} (${id})` : id
  }
}
