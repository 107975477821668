import { Language, LanguageCode } from '@axteams-one/populated-topbar'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import 'react-i18next'
import { initReactI18next } from 'react-i18next'

i18n
  .use(
    resourcesToBackend(
      (language: Language) => import(`./translation/${language}.json`)
    )
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      'common',
      'recordings',
      'settings-license',
      'settings-stream-viewers',
      'settings-encryption',
      'settings-connected-systems',
      'settings-soc',
      'video-player',
    ],
    detection: {
      lookupQuerystring: 'lang',
      // Use locally stored language choice, unless it's the first time the user
      // ends up here.
      order: ['localStorage', 'querystring', 'navigator'],
    },
    fallbackLng: LanguageCode.English,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
