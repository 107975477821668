import {
  NoAccessDark,
  NoAccessLight,
} from '@axiscommunications/fluent-illustrations'
import { useWebApplication } from '@axteams-one/bws-cloud-discovery/react'
import { makeStyles, tokens } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'

import { useThemeId } from '../providers/ThemeProvider'
import { ExternalLink } from './ExternalLink'
import { Illustration } from './Illustration'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: tokens.spacingVerticalM,
    padding: tokens.spacingHorizontalL,
  },
})

export function UndecryptableStream() {
  const styles = useStyles()
  const [themeId] = useThemeId()
  const { t } = useTranslation()
  const manual = useWebApplication('bwl-manual')

  return (
    <div data-testid="undecryptable-stream" className={styles.container}>
      <Illustration
        title={t('streams.cant-show-stream')}
        description={t('streams.public-key-missing')}
        illustration={themeId === 'light' ? NoAccessLight : NoAccessDark}
      />
      <ExternalLink
        href={`${manual?.uri}#enable-end-to-end-encryption`}
        text={t('streams.how-to-add-public-key')}
      />
    </div>
  )
}
