import {
  Body1,
  Card,
  Subtitle1,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { animated, useTransition } from 'react-spring'

import lightBackgroundImageUrl from '../../assets/light_trial_bg.png'
import darkBackgroundImageUrl from '../../assets/trial_bg.jpg'
import { AnimatedLicenseBackgroundImage } from './AnimatedLicenseBackgroundImage'

const useStyles = makeStyles({
  card: {
    position: 'relative',
    display: 'flow',
    maxWidth: '600px',
    height: '220px',
    padding: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '350px',
    height: '100%',
    position: 'absolute',
    padding: tokens.spacingHorizontalXXXL,
  },
  header: {
    marginBottom: tokens.spacingVerticalL,
  },
  description: {
    maxWidth: '300px',
    paddingBottom: '1em',
  },
})

export function NoConnectedSystem() {
  const styles = useStyles()

  const contentTransition = useTransition(0, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: { duration: 1000 },
  })
  const { t } = useTranslation()

  return (
    <Card size="large" className={styles.card}>
      <AnimatedLicenseBackgroundImage
        darkBackgroundImageUrl={darkBackgroundImageUrl}
        lightBackgroundImageUrl={lightBackgroundImageUrl}
      />
      {contentTransition((style) => (
        <animated.div
          className={mergeClasses(styles.content)}
          style={{ ...style }}
        >
          <Subtitle1 block className={styles.header}>
            {t('settings-connected-systems.no-connected-systems')}
          </Subtitle1>
          <Body1 block className={styles.description}>
            {t('settings-connected-systems.no-connected-systems-information')}
          </Body1>
          <Body1 block className={styles.description}>
            {t('settings-connected-systems.no-connected-systems-instructions')}
          </Body1>
        </animated.div>
      ))}
    </Card>
  )
}
