import {
  CodeErrorDark,
  CodeErrorLight,
} from '@axiscommunications/fluent-illustrations'
import { makeStyles, tokens } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'

import { Illustration } from '../components/Illustration'
import { useThemeId } from '../providers/ThemeProvider'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    '@supports (height: 100dvh)': {
      height: '100dvh',
    },
    alignItems: 'center',
    justifyContent: 'center',
    gap: tokens.spacingVerticalL,
  },
})

type InternalErrorPageProps = {
  message?: string
}

function InternalErrorPage({ message }: InternalErrorPageProps) {
  const styles = useStyles()
  const [themeId] = useThemeId()
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <Illustration
        title={t('common.internal-error')}
        description={message && message}
        illustration={themeId === 'light' ? CodeErrorLight : CodeErrorDark}
      />
    </div>
  )
}

export { InternalErrorPage }
