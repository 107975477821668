import {
  Button,
  Subtitle2,
  Text,
  Tooltip,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import { ArrowEnter20Regular, Live20Regular } from '@fluentui/react-icons'
import { Temporal } from '@js-temporal/polyfill'
import {
  Dispatch,
  PointerEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useThemeId } from '../../providers/ThemeProvider'
import { Flags } from '../../util/flags'
import { Stream } from '../../util/stream'
import { VideoControls } from './VideoControls'
import { PlayerState } from './VideoPlayer'

/** the diameter of the trigger bookmark, in pixels. */
const TRIGGER_BOOKMARK_DIAMETER = 6
/** The diameter of the scrubber "thumb", in pixels. */
const SCRUBBER_DIAMETER = 10
/** The height of the timeline track "progress bar", in pixels. */
const TIMELINE_TRACK_HEIGHT = 4
/** The height of target area for timeline, exceeding the timeline itself, in
 * pixels.
 */
const TIMELINE_TARGET_HEIGHT = 20
/**
 * The factor to increase size of timeline and scrubber when scrubbing, i.e. on
 * hover or active.
 */
const SCRUBBING_SIZE_FACTOR = 1.2
/** Bottom margin for live button to align with visible timeline */
const LIVE_BUTTON_BOTTOM_MARGIN =
  1 + (TIMELINE_TARGET_HEIGHT - TIMELINE_TRACK_HEIGHT) / 2

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'end',
    bottom: '100%',
    left: 0,
    right: 0,
    columnGap: tokens.spacingHorizontalL,
    padding: `50px ${tokens.spacingHorizontalM} ${tokens.spacingVerticalXXS}`,
  },
  timeline: {
    alignItems: 'center',
    display: 'flex',
    height: `${TIMELINE_TARGET_HEIGHT}px`,
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    touchAction: 'none',
    marginInline: tokens.spacingHorizontalXS,
    marginBottom: '1px',
    '@media(hover: hover) and (pointer: fine)': {
      '&:hover, &:active': {
        '> div': {
          transform: `scale(1, ${SCRUBBING_SIZE_FACTOR})`,
        },
        '& .scrubber': {
          // Only scale horizontally since vertical scaling is applied through parent.
          transform: `translate(50%, -50%) scale(${SCRUBBING_SIZE_FACTOR}, 1)`,
        },
        '& .triggerBookmark': {
          // Only scale horizontally since vertical scaling is applied through parent.
          transform: `translate(-50%, -50%) scale(${SCRUBBING_SIZE_FACTOR}, 1)`,
        },
        '& .timeLabel': {
          // Only scale horizontally since vertical scaling is applied through parent.
          transform: `scale(${SCRUBBING_SIZE_FACTOR}, 1)`,
          bottom: tokens.spacingVerticalM,
        },
      },
    },
  },
  timelineTrack: {
    backgroundColor: '#7c7b7b',
    height: `${TIMELINE_TRACK_HEIGHT}px`,
    position: 'relative',
    width: '100%',
    borderRadius: `${TIMELINE_TRACK_HEIGHT / 2}px`,
  },
  bufferProgress: {
    backgroundColor: '#707070',
    height: '100%',
    position: 'absolute',
    borderRadius: `${TIMELINE_TRACK_HEIGHT / 2}px`,
  },
  videoProgress: {
    backgroundColor: tokens.colorNeutralStrokeAccessibleSelected,
    display: 'flex',
    height: '100%',
    justifyContent: 'end',
    position: 'absolute',
    borderRadius: `${TIMELINE_TRACK_HEIGHT / 2}px`,
  },
  scrubber: {
    backgroundColor: tokens.colorNeutralStrokeAccessibleSelected,
    // temporary color from figma file
    boxShadow: `0 0 0 4px #ffcc337F, 0 4px 4px #00003F`,
    height: `${SCRUBBER_DIAMETER}px`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(50%, -50%)',
    width: `${SCRUBBER_DIAMETER}px`,
    borderRadius: '100%',
  },
  triggerBookmark: {
    backgroundColor: tokens.colorNeutralForeground1,
    height: `${TRIGGER_BOOKMARK_DIAMETER}px`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: `${TRIGGER_BOOKMARK_DIAMETER}px`,
    borderRadius: '100%',
    '> div': {
      height: '100%',
      transform: 'scale(3)',
      borderRadius: '100%',
    },
  },
  timeLabel: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    bottom: tokens.spacingVerticalL,
    userSelect: 'none',
  },
  timeLabelContent: {
    position: 'relative',
    backgroundColor: tokens.colorNeutralBackground2,
    padding: `${tokens.spacingVerticalXS} ${tokens.spacingHorizontalS}`,
    borderRadius: tokens.spacingHorizontalXS,
  },
  timeLabelArrow: {
    cursor: 'pointer',
    pointerEvents: 'auto',
    fill: tokens.colorNeutralBackground2,
  },
  timeText: {
    textAlign: 'center',
    fontVariantNumeric: 'tabular-nums',
  },
  liveText: {
    textTransform: 'uppercase',
  },
  liveButton: {
    minWidth: 'fit-content',
    marginBlockEnd: `${LIVE_BUTTON_BOTTOM_MARGIN}px`,
    opacity: 1,
  },
})

type VideoOverlayControlsProps = {
  stream: Stream
  timelineRef: React.RefObject<HTMLDivElement>
  isScrubbingRef: React.MutableRefObject<boolean>
  progressWidthRef: React.MutableRefObject<number>
  previewWidth: number | null
  setPreviewWidth: Dispatch<React.SetStateAction<number | null>>
  controlsWrapper: VideoControls
  state: PlayerState
  shouldBeLive: boolean
  setShouldBeLive: React.Dispatch<React.SetStateAction<boolean>>
  displayedPosition: number
  setScrubPosition: React.Dispatch<React.SetStateAction<number>>
  previewPosition: number
  setPreviewPosition: React.Dispatch<React.SetStateAction<number>>
}

export default function VideoOverlayControls({
  stream,
  timelineRef,
  isScrubbingRef,
  progressWidthRef,
  previewWidth,
  setPreviewWidth,
  controlsWrapper,
  state,
  shouldBeLive,
  setShouldBeLive,
  displayedPosition,
  setScrubPosition,
  previewPosition,
  setPreviewPosition,
}: VideoOverlayControlsProps) {
  const styles = useStyles()
  const [themeId] = useThemeId()

  const timeLabelRef = useRef<HTMLDivElement>(null)
  const pauseAfterScrubRef = useRef<boolean>(false)

  const enableScrubbing = !!localStorage.getItem(Flags.SCRUBBING)

  // The position of the trigger bookmark (i.e. pre buffer duration), in seconds.
  const triggerBookmarkPosition = useMemo(() => {
    const startTimestamp = stream.metadata.startTimestamp
    const triggerTimestamp = stream.metadata.triggerTimestamp

    if (!startTimestamp || !triggerTimestamp) {
      return 0
    }
    return startTimestamp.until(triggerTimestamp, { largestUnit: 'seconds' })
      .seconds
  }, [stream.metadata.startTimestamp, stream.metadata.triggerTimestamp])

  const showTriggerBookmark =
    !!state.presentedDuration &&
    !!stream.metadata.triggerTimestamp &&
    !!stream.metadata.startTimestamp &&
    triggerBookmarkPosition > 0

  // Update progressWidth continuously while not scrubbing.
  useEffect(() => {
    if (!timelineRef.current || isScrubbingRef.current) {
      return
    }
    progressWidthRef.current =
      (Math.min(displayedPosition, state.presentedDuration) /
        state.presentedDuration) *
      timelineRef.current.offsetWidth
  }, [
    displayedPosition,
    isScrubbingRef,
    progressWidthRef,
    state.presentedDuration,
    timelineRef,
  ])

  return (
    <div
      className={mergeClasses(styles.container, 'overlayControls')}
      style={{
        backgroundImage:
          themeId === 'light'
            ? `linear-gradient(transparent, rgba(255, 255, 255, 0.2))`
            : `linear-gradient(transparent, rgba(0, 0, 0, 0.2))`,
      }}
    >
      <div
        className={mergeClasses(styles.timeline, 'timelineSection')}
        data-testid={'videoControls'}
        ref={timelineRef}
        onPointerOut={handlePointerOut}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onLostPointerCapture={handleLostPointerCapture}
      >
        <div className={styles.timelineTrack}>
          <div
            className={styles.bufferProgress}
            style={{
              width: `${Math.min(
                ((displayedPosition + state.presentedBufferGap) /
                  state.presentedDuration) *
                  100,
                100
              )}%`,
            }}
          />
          <div
            className={mergeClasses(styles.videoProgress, 'videoProgress')}
            style={{
              width: shouldBeLive ? '100%' : `${progressWidthRef.current}px`,
            }}
          >
            <div className={mergeClasses(styles.scrubber, 'scrubber')} />
          </div>
          {showTriggerBookmark && (
            <TriggerBookmark
              triggerBookmarkPosition={triggerBookmarkPosition}
              state={state}
              triggerTimestamp={stream.metadata.triggerTimestamp}
            />
          )}
          {(!shouldBeLive || previewWidth) && (
            <TimeLabel
              isScrubbingRef={isScrubbingRef}
              presentedDuration={state.presentedDuration}
              displayedPosition={displayedPosition}
              progressWidthRef={progressWidthRef}
              previewWidth={previewWidth}
              timelineRef={timelineRef}
              timeLabelRef={timeLabelRef}
              startTimestamp={stream.metadata.startTimestamp}
              previewPosition={previewPosition}
            />
          )}
        </div>
      </div>
      {stream.ongoing && (
        <LiveButton
          shouldBeLive={shouldBeLive}
          setShouldBeLive={setShouldBeLive}
        />
      )}
    </div>
  )

  function previewTime(e: PointerEvent<HTMLDivElement>) {
    if (!timelineRef.current) {
      return
    }

    const x = e.clientX - timelineRef.current.getBoundingClientRect().x
    const progress = x / timelineRef.current.offsetWidth
    const newPosition = Math.max(
      Math.min(progress * state.presentedDuration, state.presentedDuration),
      0
    )

    setPreviewWidth(
      (newPosition / state.presentedDuration) * timelineRef.current.offsetWidth
    )

    setPreviewPosition(newPosition)
  }

  function handlePointerOut() {
    setPreviewWidth(null)
  }

  function handlePointerDown(e: PointerEvent<HTMLDivElement>) {
    e.buttons === 1 && startScrubbing(e)
  }

  function handlePointerMove(e: PointerEvent<HTMLDivElement>) {
    isScrubbingRef.current && e.buttons === 1 ? scrub(e) : previewTime(e)
  }

  function handlePointerUp(e: PointerEvent<HTMLDivElement>) {
    isScrubbingRef.current && stopScrubbing(e)
  }

  function handleLostPointerCapture(e: PointerEvent<HTMLDivElement>) {
    /**
     * Pointer capture is occasionally lost while scrubbing. This issue is
     * especially apparent on Windows Chrome.
     */
    if (isScrubbingRef.current && timelineRef.current) {
      timelineRef.current.setPointerCapture(e.pointerId)
    }
  }

  function scrub(e: PointerEvent<HTMLDivElement>) {
    if (!timelineRef.current) {
      return
    }

    // If primary button (left mouse or touch contact) is not currently pressed,
    // the scrubber is 'stuck' to mouse -> stop scrubbing.
    if (e.buttons !== 1) {
      stopScrubbing(e)
      return
    }
    const x = e.clientX - timelineRef.current.getBoundingClientRect().x
    const progress = x / timelineRef.current.offsetWidth
    const newPosition = Math.max(
      Math.min(progress * state.presentedDuration, state.presentedDuration),
      0
    )

    progressWidthRef.current =
      (newPosition / state.presentedDuration) * timelineRef.current.offsetWidth

    setScrubPosition(newPosition)

    enableScrubbing && controlsWrapper.setPosition(newPosition)
  }

  function startScrubbing(e: PointerEvent<HTMLDivElement>) {
    if (!timelineRef.current) {
      return
    }
    timelineRef.current.setPointerCapture(e.pointerId)
    // If player was paused before scrubbing, it should stay paused after scrubbing.
    pauseAfterScrubRef.current = state.status === 'PAUSED'
    isScrubbingRef.current = true
    controlsWrapper.pause()
    scrub(e)
  }

  function stopScrubbing(e: PointerEvent<HTMLDivElement>) {
    if (!timelineRef.current) {
      return
    }
    timelineRef.current.releasePointerCapture(e.pointerId)
    isScrubbingRef.current = false
    !enableScrubbing && controlsWrapper.setPosition(displayedPosition)
    !pauseAfterScrubRef.current && controlsWrapper.play()
  }
}

type TriggerBookmarkProps = {
  triggerBookmarkPosition: number
  state: PlayerState
  triggerTimestamp?: Temporal.ZonedDateTime
}

function TriggerBookmark({
  triggerBookmarkPosition,
  state,
  triggerTimestamp,
}: TriggerBookmarkProps) {
  const styles = useStyles()
  const { t } = useTranslation()

  if (!triggerTimestamp) {
    return null
  }

  const content = t('video-player.trigger-time', {
    timestamp: triggerTimestamp.toPlainTime().toLocaleString(),
  })

  return (
    <Tooltip content={content} relationship={'label'}>
      <div
        className={mergeClasses(styles.triggerBookmark, 'triggerBookmark')}
        style={{
          left: `${Math.min(
            (triggerBookmarkPosition / state.presentedDuration) * 100,
            100
          )}%`,
        }}
      />
    </Tooltip>
  )
}

type TimeLabelProps = {
  isScrubbingRef: React.MutableRefObject<boolean>
  presentedDuration: number
  displayedPosition: number
  progressWidthRef: React.MutableRefObject<number>
  previewWidth: number | null
  timelineRef: React.RefObject<HTMLDivElement>
  timeLabelRef: React.RefObject<HTMLDivElement>
  startTimestamp?: Temporal.ZonedDateTime
  previewPosition: number
}

function TimeLabel({
  isScrubbingRef,
  displayedPosition,
  progressWidthRef,
  previewWidth,
  timelineRef,
  timeLabelRef,
  startTimestamp,
  previewPosition,
}: TimeLabelProps) {
  const styles = useStyles()
  const { t } = useTranslation()

  const timeStyle = useCallback(() => {
    // Hide until ref has been set to avoid faulty positioning.
    if (
      !progressWidthRef.current ||
      !timeLabelRef.current ||
      !timelineRef.current
    ) {
      return { display: 'none' }
    }

    const positionWidth =
      previewWidth && !isScrubbingRef.current
        ? previewWidth
        : progressWidthRef.current

    // If distance between scrubber and start of timeline is
    // smaller than half of time label width then left position
    // time label (default positioning).
    if (positionWidth - timeLabelRef.current?.offsetWidth / 2 < 0) {
      return
    }
    // If distance between scrubber and end of timeline is
    // smaller than half of time label width then right position
    // time label.
    if (
      positionWidth + timeLabelRef.current.offsetWidth / 2 >=
      timelineRef.current.offsetWidth
    ) {
      return { right: 0 }
    }
    // Else center position time label with scrubber.
    return {
      left: positionWidth - timeLabelRef.current?.offsetWidth / 2,
    }
  }, [
    progressWidthRef,
    timeLabelRef,
    timelineRef,
    previewWidth,
    isScrubbingRef,
  ])

  const position =
    previewWidth && !isScrubbingRef.current
      ? previewPosition
      : displayedPosition

  const localTime = startTimestamp
    ? startTimestamp
        .add(
          Temporal.Duration.from({
            seconds: Number(Math.round(position)),
          })
        )
        .toPlainTime()
        .toLocaleString()
    : t('common.na')

  return (
    <div
      className={mergeClasses(styles.timeLabel, 'timeLabel')}
      ref={timeLabelRef}
      style={timeStyle()}
    >
      <div className={styles.timeLabelContent}>
        <Text className={styles.timeText}>{localTime}</Text>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="8"
        viewBox="0 0 16 8"
      >
        <path d="M0,0 l8,8 l8,-8" className={styles.timeLabelArrow} />
      </svg>
    </div>
  )
}

type LiveButtonProps = {
  shouldBeLive: boolean
  setShouldBeLive: React.Dispatch<React.SetStateAction<boolean>>
}

function LiveButton({ shouldBeLive, setShouldBeLive }: LiveButtonProps) {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <Button
      className={mergeClasses(styles.liveButton, 'liveButton')}
      icon={shouldBeLive ? <Live20Regular /> : <ArrowEnter20Regular />}
      appearance="primary"
      disabled={shouldBeLive}
      onClick={() => setShouldBeLive(true)}
    >
      <Subtitle2 className={styles.liveText}>
        {t('video-player.live')}
      </Subtitle2>
    </Button>
  )
}
