import {
  Toast as FluentToast,
  Link,
  ToastBody,
  ToastTitle,
  ToastTrigger,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'

type ToastProps = {
  title: string
  body: string
  dismissible?: boolean
  testId?: string
}

export function Toast({ title, body, dismissible, testId }: ToastProps) {
  const { t } = useTranslation()

  // Undefined dismissAction makes it have no effect
  const dismissAction = dismissible ? (
    <ToastTrigger>
      <Link>{t('common.action.dismiss')}</Link>
    </ToastTrigger>
  ) : undefined

  return (
    <FluentToast data-testid={testId}>
      <ToastTitle action={dismissAction}>{title}</ToastTitle>
      <ToastBody>{body}</ToastBody>
    </FluentToast>
  )
}
