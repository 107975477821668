import { useTrackPageView } from '@axteams-one/bws-cloud-metrics/react'
import { makeStyles, tokens } from '@fluentui/react-components'
import { useNavigate, useParams } from 'react-router-dom'

import { UndecryptableStream } from '../components/UndecryptableStream'
import { StreamEnded } from '../components/VideoPlayer/StreamEnded'
import { VideoPlayerContainer } from '../components/VideoPlayer/VideoPlayerContainer'
import { useContentKeys } from '../hooks/useContentKeys'
import { NoEndToEndEncryptionPage } from './NoEndToEndEncryptionPage'
import { useStreamsPageContext } from './StreamsPage'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: tokens.colorStrokeFocus1,
  },
})

function LivePlayerPage() {
  useTrackPageView()
  const styles = useStyles()
  const navigate = useNavigate()
  const { streams } = useStreamsPageContext()
  const { streamId = '' } = useParams()
  const stream = streams.find((stream) => stream.id === streamId)
  const ongoing = stream?.ongoing ?? false
  const decryptable = stream?.decryptable ?? false

  const [keys] = useContentKeys()

  if (!keys.length) {
    return <NoEndToEndEncryptionPage />
  }

  if (!decryptable) {
    return <UndecryptableStream />
  }

  if (!ongoing) {
    return (
      <div className={styles.container}>
        <StreamEnded
          streamId={streamId}
          onViewRecording={handleViewRecording}
          onDismiss={handleDismissEndedStream}
        />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <VideoPlayerContainer keys={keys} streamId={streamId} />
    </div>
  )

  function handleViewRecording(streamId: string) {
    navigate(`/recordings/${streamId}`)
  }

  function handleDismissEndedStream() {
    navigate('/streams')
  }
}

export { LivePlayerPage }
