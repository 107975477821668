import {
  InputOnChangeData,
  ToggleButton,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { SearchBox } from '@fluentui/react-search-preview'
import { ChangeEvent, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Filter } from '../hooks/useStreamsFilter'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalM,
    whiteSpace: 'nowrap',
  },
  searchInput: {
    // Add margin to account for absolute positioned compress sidebar button.
    marginInlineEnd: tokens.spacingHorizontalXXXL,
  },
  buttons: {
    display: 'flex',
    columnGap: tokens.spacingHorizontalXS,
  },
  button: {
    minWidth: '0',
    flexShrink: 0,
  },
})

type StreamsFilterProps = {
  filter: Filter
  onFilter: (filter: Filter) => void
  search: string
  onSearch: (
    _event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLSpanElement>,
    data: InputOnChangeData
  ) => void
}

export default function StreamsFilter({
  filter,
  onFilter,
  search,
  onSearch,
}: StreamsFilterProps) {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <SearchBox
        className={styles.searchInput}
        size="small"
        appearance="filled-darker"
        placeholder={t('streams.search-for-user-or-group')}
        value={search}
        onChange={onSearch}
      />
      <div className={styles.buttons}>
        <ToggleButton
          className={styles.button}
          shape="circular"
          size="small"
          appearance="subtle"
          checked={filter === Filter.all}
          onClick={() => {
            onFilter(Filter.all)
          }}
        >
          {t('common.all')}
        </ToggleButton>
        <ToggleButton
          className={styles.button}
          shape="circular"
          size="small"
          appearance="subtle"
          checked={filter === Filter.streams}
          onClick={() => {
            onFilter(Filter.streams)
          }}
        >
          {t('streams.camera-users')}
        </ToggleButton>
        <ToggleButton
          className={styles.button}
          shape="circular"
          size="small"
          appearance="subtle"
          checked={filter === Filter.groups}
          onClick={() => {
            onFilter(Filter.groups)
          }}
        >
          {t('streams.groups')}
        </ToggleButton>
      </div>
    </div>
  )
}
