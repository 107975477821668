import { useCallback, useEffect, useState } from 'react'
import { Client } from 'urql'

import {
  ErrorCode,
  GetStreamDocument,
  GetStreamQuery,
} from '../graphql/__generated__/graphql'
import { streamMapper } from '../util/mapper'
import { Stream } from '../util/stream'
import { ApiResult, ApiStatus, InternalErrorCode } from './types'

function useGetStream(
  client?: Client,
  streamId?: string,
  recipients?: string[]
): [ApiResult<Stream>, () => void] {
  const [result, setResult] = useState<ApiResult<Stream>>({
    status: ApiStatus.Idle,
  })

  const getStream = useCallback(() => {
    if (!client || !streamId) {
      return
    }

    // Preserve result on reexecution of previously resolved query to prevent re-mount.
    setResult((prevResult) =>
      prevResult.status === ApiStatus.Resolved &&
      prevResult.data.id === streamId
        ? prevResult
        : { status: ApiStatus.Loading }
    )

    client
      .query<GetStreamQuery>(
        GetStreamDocument,
        {
          streamId,
          options: { recipients },
        },
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then((result) => {
        if (result.error) {
          // If we add a new error which is not in ErrorCode yet it will still
          // be set and the consumer will have to handle it with an else.
          const errorCode = result.error.graphQLErrors[0].extensions.code
          // If query fails retain previous result to allow current stream to
          // remain in view.
          setResult((previousResult) =>
            previousResult.status === ApiStatus.Resolved &&
            previousResult.data.id === streamId
              ? previousResult
              : {
                  status: ApiStatus.Rejected,
                  error: errorCode as ErrorCode,
                }
          )
        } else {
          const data = result.data?.getStream
          if (data && streamId) {
            setResult({
              status: ApiStatus.Resolved,
              data: { ...streamMapper(data) },
            })
          }
        }
      })
      .catch(() => {
        setResult((prevResult) =>
          prevResult.status === ApiStatus.Resolved &&
          prevResult.data.id === streamId
            ? prevResult
            : {
                status: ApiStatus.Rejected,
                error: InternalErrorCode.NetworkError,
              }
        )
      })
  }, [client, streamId, recipients])

  useEffect(() => {
    getStream()
  }, [getStream])

  return [result, getStream]
}

export { useGetStream }
