import { Client } from '@axteams-one/bws-cloud-azure-web-pubsub'
import { useToastController } from '@fluentui/react-components'
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { TOASTER_ID } from '../constants'
import { useNotificationsWebSocket } from '../hooks/useNotificationsWebSocket'
import { Toast } from '../util/Toasts'
import { useCurrentOrganization } from './CurrentOrganizationProvider'

type NotificationsContextProps = {
  client: Client | undefined
}

const NotificationsContext = createContext<NotificationsContextProps>(
  {} as NotificationsContextProps
)

export function NotificationsProvider({ children }: PropsWithChildren) {
  const { id: organizationId } = useCurrentOrganization()

  const { dispatchToast, dismissToast } = useToastController(TOASTER_ID)
  const [hadError, setHadError] = useState(false)
  const { t } = useTranslation()

  const { client, error } = useNotificationsWebSocket({
    organizationId,
  })

  useEffect(() => {
    if (error) {
      setHadError(true)
      dispatchToast(
        <Toast
          title={t('streams.degraded-experience')}
          body={t('streams.connection-lost-streams')}
          dismissible={true}
          testId={'notificationsProviderErrorToast'}
        />,
        {
          timeout: -1,
          intent: 'warning',
          toastId: 'pubSubWarning',
          position: 'bottom-end',
        }
      )
    } else if (hadError) {
      setHadError(false)
      // In case it manages to connect, without the error having been dismissed
      dismissToast('pubSubWarning')
      dispatchToast(
        <Toast
          title={t('streams.connection-reestablished')}
          body={t('streams.now-receiving-stream-updates')}
          testId={'notificationsProviderSuccessToast'}
        />,
        {
          intent: 'success',
          toastId: 'pubSubSuccess',
          position: 'bottom-end',
        }
      )
    }
  }, [dismissToast, dispatchToast, error, hadError, t])

  const notificationsState: NotificationsContextProps = {
    client,
  }

  return (
    <>
      <NotificationsContext.Provider value={notificationsState}>
        {children}
      </NotificationsContext.Provider>
    </>
  )
}

export function useNotificationsClient() {
  return useContext(NotificationsContext)
}
