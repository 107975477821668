import { PasswordInput } from '@axiscommunications/fluent-password-input'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Field,
  InputOnChangeData,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogActions: {
    justifyContent: 'end',
  },
})

type ImportDialogProps = {
  onClose: () => void
  onImportKeys: (passphrase: string) => void
  errorMessage: string
}

export function ImportKeysDialog({
  onClose,
  onImportKeys,
  errorMessage,
}: ImportDialogProps) {
  const styles = useStyles()
  const { t } = useTranslation()
  const [passphrase, setPassphrase] = useState('')

  return (
    <Dialog open>
      <DialogSurface>
        <form onSubmit={handleSubmit}>
          <DialogBody className={styles.dialogBody}>
            <DialogTitle>
              {t('settings-encryption.import-keys-dialog')}
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Field validationMessage={errorMessage}>
                <PasswordInput onChange={handleChange} value={passphrase} />
              </Field>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary" onClick={onClose}>
                  {t('common.action.cancel')}
                </Button>
              </DialogTrigger>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  type="submit"
                  appearance="primary"
                  disabled={!passphrase}
                >
                  {t('settings-encryption.import-keys-decrypt')}
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    onImportKeys(passphrase)
  }

  function handleChange(
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setPassphrase(data.value)
  }
}
