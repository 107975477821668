import {
  Button,
  Tooltip,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from '@fluentui/react-components'
import {
  FullScreenMaximize20Regular,
  FullScreenMinimize20Regular,
  Map20Regular,
  Play20Regular,
} from '@fluentui/react-icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  container: {
    backgroundColor: tokens.colorNeutralBackground2,
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    columnGap: tokens.spacingHorizontalM,
    ...shorthands.padding(tokens.spacingVerticalXS),
  },

  rightSection: {
    justifySelf: 'right',
    gridColumnStart: 3,
  },
})

type StreamsHeaderProps = {
  onFullscreen: () => void
  onToggleMap: () => void
  showMap: boolean
  isFullscreen: boolean
}

export default function StreamsHeader({
  onFullscreen,
  onToggleMap,
  showMap,
  isFullscreen,
}: StreamsHeaderProps) {
  const styles = useStyles()
  const { t } = useTranslation()

  const fullscreenIcon = isFullscreen ? (
    <FullScreenMinimize20Regular />
  ) : (
    <FullScreenMaximize20Regular />
  )

  return (
    <header className={mergeClasses(styles.container, 'streamsHeader')}>
      <Tooltip
        content={showMap ? t('streams.show-video') : t('streams.show-map')}
        mountNode={document.fullscreenElement}
        relationship={'label'}
      >
        <Button
          data-testid={'toggleMap'}
          appearance="subtle"
          icon={showMap ? <Play20Regular /> : <Map20Regular />}
          onClick={onToggleMap}
        />
      </Tooltip>

      <div className={styles.rightSection}>
        <Tooltip
          content={t('streams.fullscreen')}
          mountNode={document.fullscreenElement}
          relationship={'label'}
        >
          <Button
            data-testid={'toggleFullscreen'}
            appearance="subtle"
            icon={fullscreenIcon}
            onClick={onFullscreen}
          />
        </Tooltip>
      </div>
    </header>
  )
}
